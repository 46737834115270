import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {CircularProgress} from "@material-ui/core";

import logo from "../webpt-logo.png";

interface MeetingLoadingProps {
  requestId: number;
  reloadMeetingData: () => void;
  meetingUrl: string;
}

const useStyles = makeStyles({
  root: {
    width: 320,
    height: "auto",
    padding: "20px",
    textAlign: "center"
  },
  logo: {
    width: "100%",
    margin: "0 auto"
  },
  loader: {
    color: "#6EC4E9"
  }
});

const MeetingWaiting: React.FC<MeetingLoadingProps> = ({ requestId, meetingUrl, reloadMeetingData }) => {
  const classes = useStyles();
  useEffect(() => {
    if (meetingUrl !== "") {
      window.location.href = meetingUrl;
      return;
    }

    setTimeout(reloadMeetingData, 10000);
  }, [requestId, meetingUrl, reloadMeetingData]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          <img className={classes.logo} alt="WebPT" src={logo} />
        </Typography>
        <CircularProgress className={classes.loader} />
        <Typography variant="h6" component="p">
          Waiting for your provider to start the appointment
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MeetingWaiting;