import React, {useCallback, useEffect, useState} from 'react';
import { RouteComponentProps } from "react-router-dom";
import axios from "axios";

import MeetingSearching from "./MeetingSearching";
import MeetingWaiting from "./MeetingWaiting";
import MeetingInvalid from "./MeetingInvalid";

interface MeetingLoaderProps extends RouteComponentProps<{ id: string }> {}

interface MeetingResponse {
  token: string;
  id: string;
  status: "CREATED" |  "IN_PROGRESS" | "ENDED";
  join_url: string;
}

const meetingApiUrl = process.env.REACT_APP_MEETING_API_URL || "";

const getMeetingInfo = async(token: string): Promise<MeetingResponse> => {
  const meeting = await axios.get(`${meetingApiUrl}/meetings?token=${token}&latest=1`);
  const { id, status, join_url = "" } = meeting.data;
  return {
    id,
    token,
    status,
    join_url
  }
};

const MeetingLoader: React.FC<MeetingLoaderProps> = ({ match: { params: { id }}})  => {
  const [hasMeeting, setHasMeeting] = useState<boolean | undefined>(undefined);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [request, setRequest] = useState(Date.now());

  const fetchMeetingInfo = useCallback(async() => {
    try {
      const meetingData = await getMeetingInfo(id);
      setRequest(Date.now());
      setHasMeeting(true);

      if (meetingData.status === "IN_PROGRESS") {
        setMeetingUrl(meetingData.join_url)
      }
    } catch (e) {
      setHasMeeting(false);
      console.error(e);
    }
  }, [id]);

  useEffect(() => {
    fetchMeetingInfo();
  }, [fetchMeetingInfo]);

  if (hasMeeting === false) {
    return <MeetingInvalid />
  }

  return hasMeeting
    ? <MeetingWaiting requestId={request} reloadMeetingData={fetchMeetingInfo} meetingUrl={meetingUrl} />
    : <MeetingSearching  />;
};

export default MeetingLoader;