import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import logo from "../webpt-logo.png";

const useStyles = makeStyles({
  root: {
    width: 320,
    height: "auto",
    padding: "20px",
    textAlign: "center"
  },
  logo: {
    width: "100%",
    margin: "0 auto"
  },
  error: {
    color: "#D9272D"
  }
});

export default function MeetingLoading() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          <img className={classes.logo} alt="WebPT" src={logo} />
        </Typography>
        <Typography variant="h5" component="h2">
          <ErrorIcon className={classes.error} />
        </Typography>
        <Typography variant="body2" component="p">
          Oops something went wrong! Please contact your clinic to resend the meeting link.
        </Typography>
      </CardContent>
    </Card>
  );
}