import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import MeetingInvalid from "./components/MeetingInvalid";
import MeetingLoader from "./components/MeetingLoader";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center"
  }
});

const App = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Router>
        <Switch>
          <Route exact path="/meeting/:id" component={MeetingLoader} />
          <Route path="*">
            <MeetingInvalid />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App
