import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {CircularProgress} from "@material-ui/core";
import logo from "../webpt-logo.png";

const useStyles = makeStyles({
  root: {
    width: 320,
    height: "auto",
    padding: "20px",
    textAlign: "center"
  },
  logo: {
    width: "100%",
    margin: "0 auto"
  },
  loader: {
    color: "#6EC4E9"
  }
});

export default function MeetingSearching() {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          <img className={classes.logo} alt="WebPT" src={logo} />
        </Typography>
        <CircularProgress className={classes.loader} />
        <Typography variant="h6" component="p">
          Loading Virtual Visit
        </Typography>
      </CardContent>
    </Card>
  );
}